import { useMemo } from 'react';

export const LocalTime = ({ date, timeZone }) => {
  const { hour, minute, modifier } = useMemo(
    () =>
      new Date(date)
        .toLocaleTimeString('en-US', { timeZone, hour: 'numeric', minute: '2-digit' })
        .match(/^(?<hour>\d+):(?<minute>\d+)\s(?<modifier>\w+)$/).groups,
    [date, timeZone]
  );

  return (
    <>
      <div className="hour">
        {hour}
        {!!Number(minute) && <span className="minute position-absolute">{minute}</span>}
      </div>
      <div className="hour-modifier">{modifier.toLocaleLowerCase()}</div>
    </>
  );
};
