import { useEffect, useRef, useState } from 'react';

export const useNow = () => {
  const [now, setNow] = useState(new Date());
  const timer = useRef();

  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }

    timer.current = setInterval(() => {
      const date = new Date();
      if (date.getHours() !== now.getHours()) {
        setNow(date);
      }
    }, 1000);

    return () => clearInterval(timer.current);
  }, [now, setNow, timer]);

  return now;
};
