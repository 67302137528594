import { useCallback, useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import classNames from 'classnames';
import { LocalTime } from './LocalTime';
import { useNow } from './useNow';
import { useDst } from './useDst';
import 'bootstrap/dist/css/bootstrap.min.css';

const rawSzymonHours = [4, 5, 6, 7, 8, 9, 17.75, 18, 19.25];
const vikasWorkingHours = [1.75, 2, 3, 4, 5, 6, 7, 8.25, 17.75, 18.25];

export const App = () => {
  useEffect(() => {
    ReactGA.send('pageview');
  }, []);

  const now = useNow();
  const isDst = useDst();

  const szymonHours = useMemo(() => (isDst ? rawSzymonHours.map(hour => (hour + 1) % 24) : rawSzymonHours), [isDst]);

  const places = useMemo(
    () => [
      { name: 'Seattle', tz: 'America/Los_Angeles' },
      { name: 'Szymon', subtitle: 'Katowice', tz: 'Europe/Warsaw', workingHours: szymonHours },
      { spacer: true, isHidden: true },
      { name: 'Vikas', subtitle: 'Delhi', tz: 'Asia/Kolkata', workingHours: vikasWorkingHours, isHidden: true },
    ],
    [szymonHours]
  );

  const isAll = useMemo(() => document.location.pathname === '/all', []);
  const filteredPlaces = useMemo(() => places.filter(place => !place.isHidden || isAll), [isAll]);

  const isCurrentHour = useCallback(
    date => date.getHours() >= now.getHours() && date.getHours() < now.getHours() + 1,
    [now]
  );

  const isWorkingHour = useCallback((date, workingHours) => workingHours.includes(date.getUTCHours()), []);
  const isFirstHalfWorking = useCallback((date, workingHours) => workingHours.includes(date.getUTCHours() + 0.25), []);
  const isSecondHalfWorking = useCallback((date, workingHours) => workingHours.includes(date.getUTCHours() + 0.75), []);

  const getHours = useCallback(
    workingHours => {
      const startingTime = new Date().setHours(1, 0, 0, 0);

      return new Array(24).fill(startingTime).map((time, i) => {
        const date = new Date(time);
        return {
          date: date.setHours(date.getHours() + i),
          isCurrent: isCurrentHour(date),
          isWorking: isWorkingHour(date, workingHours),
          isFirstHalfWorking: isFirstHalfWorking(date, workingHours),
          isSecondHalfWorking: isSecondHalfWorking(date, workingHours),
        };
      });
    },
    [isCurrentHour, isWorkingHour, isFirstHalfWorking, isSecondHalfWorking]
  );

  return (
    <Container className="py-2">
      <Table bordered responsive size="sm">
        <tbody>
          {filteredPlaces.map((place, i) => {
            if (place.spacer) {
              return <tr key={i} className="spacer" />;
            }

            return (
              <tr key={place.name + place.subtitle}>
                <td className="align-middle font-weight-bold">
                  {place.name}
                  {place.subtitle && <div className="text-xs font-weight-light text-secondary">{place.subtitle}</div>}
                </td>
                {getHours(place.workingHours || []).map(
                  ({ date, isCurrent, isWorking, isFirstHalfWorking, isSecondHalfWorking }, i) => (
                    <td
                      key={i}
                      className={classNames('text-center', 'position-relative', {
                        working: isWorking,
                        'working-first-half': isFirstHalfWorking,
                        'working-second-half': isSecondHalfWorking,
                        current: isCurrent,
                      })}
                    >
                      <LocalTime date={date} timeZone={place.tz} />
                    </td>
                  )
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};
