import { useCallback } from 'react';
import { CURRENT_WORKING_TZ } from './constants';

export const useDst = () => {
  const now = new Date();
  const january = new Date(now.getFullYear(), 0, 1);
  const july = new Date(now.getFullYear(), 6, 1);

  const getTimezoneOffset = useCallback(date => {
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: CURRENT_WORKING_TZ,
      timeZoneName: 'short',
    });
    const parts = formatter.formatToParts(date);
    const timeZoneName = parts.find(part => part.type === 'timeZoneName').value;

    const match = timeZoneName.match(/GMT([+-]\d+)/);
    return match ? parseInt(match[1], 10) * 60 : 0;
  }, []);

  const standardOffset = Math.max(getTimezoneOffset(january), getTimezoneOffset(july));
  const currentOffset = getTimezoneOffset(now);

  return currentOffset < standardOffset;
};
